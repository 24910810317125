.day-box {
    border: 1px solid #ccc;
    padding: 0.5rem;
    border-radius: 0.5rem;
    gap: 0.2rem;
    flex-grow:1;
    margin: 0.2rem;
    flex:1;
}

.day-title {
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 5px;
}

.day-class {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 12px;
    margin-bottom: 5px;
    border: 1px solid #eee;
    cursor: pointer;

}

.day-class-timetable {
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    padding: 0.5rem;
    border-radius: 0.5rem;
    font-size: 12px;
    margin-bottom: 5px;
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.day-class:hover {
    background-color: rgba(165, 165, 165, 0.251);
}

.classday-title {
    font-size: 12px;
    font-weight: bold;
}

.classday-field {
    color: gray
}

.test-scheduled {
    cursor:pointer
}

.test-scheduled:hover {
    color: #0097c9
}