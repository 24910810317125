.classtitle {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  position: absolute;
  width: 100px;
  height: 17px;
  left: 9px;
  top: 7px;
  overflow: hidden;
}

.frame {
  position: relative;
  margin: 2px;
  background: linear-gradient(49deg, #2a2a2a 2.96%, #464646 98.43%);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.beginner {
  position: relative;
  margin: 2px;
  background: linear-gradient(to right, #017422, #155d31);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}
.beginner-legend {
   background: linear-gradient(to right, #017422, #155d31);
   color: white;
   text-align: center;
   padding:2px
}

.beginner:hover {
  filter: brightness(1.2);
}

.intermediate {
  position: relative;
  margin: 2px;
  background: linear-gradient(to right, #000b87, #00356d);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.intermediate-legend {
  color: white;
  background: linear-gradient(to right, #000b87, #00356d);
    text-align: center;
   padding:2px
}


.intermediate:hover {
  filter: brightness(1.2);
}

.advanced {
  position: relative;
  margin: 2px;
  background: linear-gradient(to right, #4e0092, #570083);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.advanced-legend {
  color: white;
  background: linear-gradient(to right, #4e0092, #570083);
    text-align: center;
   padding:2px
}

.advanced:hover {
  filter: brightness(1.2);
}

.class-coming {
  position: relative;
  margin: 2px;
  background: linear-gradient(
    232deg,
    rgba(174, 175, 4, 1) 0%,
    rgba(94, 90, 1, 1) 100%
  );
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.class-coming:hover {
  position: relative;
  margin: 2px;
  background: linear-gradient(
    232deg,
    rgb(201, 201, 5) 0%,
    rgb(128, 121, 1) 100%
  );
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.frame:hover {
  position: relative;
  margin: 2px;
  background: linear-gradient(49deg, #014e72 2.96%, #018099 98.43%);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.frameDisabled {
  position: relative;
  margin: 2px;
  background: linear-gradient(254.96deg, #627881 2.96%, #98a5aa 98.43%);
  border-radius: 5px;
  box-shadow: 3px 3px 10px #636d71;
}

.teachersName {
  position: relative;
  top: 7px;
  right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;

  text-align: right;

  color: #dddddd;
}

.unknownTeachersName {
  position: relative;
  top: 7px;
  right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;

  text-align: right;

  color: #e29d9d;
}

.classRoom {
  position: relative;
  top: 7px;
  right: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;

  text-align: right;

  color: #e2e2e2;
}

.activeStudents {
  position: absolute;
  width: 110px;
  height: 17px;
  left: 9px;
  top: 30px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height */

  /* color: #47ff43; */
}

.pendingStudents {
/*   position: absolute;
  width: 67px;
  height: 17px;
  left: 9px;
  top: 49px; */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  /* identical to box height */

  color: #dfe300;
}
.other-teacher {
  position: absolute;
  left: 10px;
  top: 48px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height */

  text-align: left;

  color: #00eeff;
}

.timetable-search {
  position: relative;
  top: -40px;
  float: right;
}

.class-link {
  text-decoration: none;
}

.custom-context-menu {
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.068);
  z-index: 1000;
  border-radius: 5px;
  width: 300px;
  position:absolute;
  padding: 5px;
}

.context-button {
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
    display: flex;
  align-items: center;
  gap:5px;
}

.context-button:hover {
  background-color: #d6d6d6;

}

.context-title {
  font-weight: bold;

  color: rgb(0, 70, 108);
  text-align: center;
  width:300px
}

.draggable {
  width: 150px; 
  height: 150px; 
  background-color: #4CAF50; 
  color: white; 
  text-align: center; 
  line-height: 150px; 
  position: absolute; 
  cursor: move; 
}

.class-view-test {
  color: rgb(111, 252, 111);
}