.input-box {

  border: none;
  font-size: 16px;
}

.input-box:focus {
    border: none;
    outline: none;
}

.students-list {
    position:absolute;
    z-index:100;
    background-color: white;
    margin-top: 10px;
    overflow-y: scroll;
    height: 250px;
   
}

.tagged {
    max-width: 400px;
    margin-right: 10px;
    display: flex;
    flex-wrap: wrap;
   
}

.student-card {
    width: 550px;
    padding: 10px;
    cursor: pointer;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: white;
}
.student-card-selected {
    width: 550px;
    padding: 10px;
    cursor: pointer;
     border-radius: 5px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     background-color: #eee;
}

.student-card:hover {
    background-color: #eee;
}

.phone-number{
    font-size: 12px;
    color: #aaa;
}