.clock-face {
    position: relative;
    width: 99%;
    max-width: 298px;
    height: 99%;
    max-height: 298px;
    border: 2px solid rgb(223, 222, 222);
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgb(175, 175, 175) 100%, rgba(73,73,73,1) 100%);
}

/* .clock-border {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
    border-radius: 50%;
    border: 1px solid rgb(238, 238, 238);
    background: linear-gradient(45deg, rgba(207,207,207,1) 0%, rgba(255,255,255,1) 49%, rgba(228,228,228,1) 100%, rgba(73,73,73,1) 100%, rgba(0,0,0,1) 100%);
    margin: auto; 
    display: flex;
    justify-content: center;
    align-items: center; 
} */

.number {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: rgba(211, 211, 211, 0);
    transition: all 0.3s;
    cursor: pointer;
}

.number:hover {
    background: rgb(0, 72, 117);
    color: white;
}

.hour-hand, .minute-hand {
    position: absolute;
    background-color: black;
    border-radius: 50%;
    transform-origin: bottom center;
    bottom: 50%;
}

.hour-hand {
    width: 6px;
    height: 20%;
    z-index: 0;
    color:#ccc;
    background-color: #ccc;
}

.minute-hand {
    width: 4px;
    height: 30%;
    z-index: 0;
    color:#ccc;
    background-color: #ccc;
}

.ampm {
    position: absolute;
    bottom: 25%;
    background-color: rgba(255, 255, 255, 0.568);
    padding: 2px 5px;
    border: 1px solid rgb(159, 159, 159);
    border-radius: 3px;
    cursor: pointer;
    z-index: 100;
}

.ampm:hover {
    background-color: rgb(215, 215, 215);

}

.clock-time {
    position: absolute;
    top: 25%;
    color: rgb(0, 72, 117);
    font-size: 18px;
    z-index: 100;
}

.clock-label {
    text-align: center;
    color:#969696;
    font-size: 12px;
    padding: 5px;
}