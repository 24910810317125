.message {
  border-radius: 10px;
  box-shadow: 2px 2px 10px #888888;
  border: 1px solid #ccc;
  overflow-y: hidden;
  /*  margin-bottom: 5px; */
}

.smText {
  font-size: 11px;
  color: #888888;
}

.neat {
  text-decoration: none;
  color: #000000;
  cursor: pointer;
}

.neat:hover {
  color: #00738a;
}

.information {
  display: flex;
  padding: 5px;
}

.message-heading {
  border-radius: 10px, 10px;
  padding: 5px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    80deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 62, 93, 1) 0%,
    rgba(0, 126, 170, 1) 99%
  );
}

.message-description {
  font-size: 0.9em;
  overflow-y: auto;
  margin-right: 5px;
}

.close-message-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-actions-taken {
  font-size: 0.9em;
}
