body {
  /*  overflow: hidden; */
}

::-webkit-scrollbar {
  width: 7px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(131, 131, 131);
  border-radius: 10px;
}

.content {
  overflow: auto;
}

.dflex-sb {
  display: flex;
  justify-content: space-between;
}
