.history-status {
  width: 150px;
}

.history-startTime {
  width: 150px;
}

.history-endTime {
  width: 150px;
}

.history-returnDate {
  width: 150px;
}

.history-changedBy {
  width: 150px;
}

.history-reason {
  flex-grow: 1;
}
