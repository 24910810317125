.search-students {
  display: block;
  position: absolute;
  top: 70px;
  margin-left: 30%;
  margin-right: auto;
  box-shadow: 3px 3px 5px #777777;
  z-index: 100;
  max-height: 90vh;

  overflow-y: auto;
  overflow-x: hidden;
}

.student-search {
  display: flex;
  align-items: center;
  padding: 10px;
  width: 450px;
}

.student-search-link {
  text-decoration: none;
}

.search-end-icon {
  cursor: pointer;
}
