.customer-students {
  width: 100%;
  background: linear-gradient(
    200deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(212, 212, 212, 1) 100%
  );
  margin: 5px;
  border-radius: 10px;
  padding: 10px;
}
