.money-subtitle {
    color: #0090c4;
    border-bottom: 1px #ccc solid;  
    font-weight: bold;
    padding-bottom: 5px; 
}

.money-outline {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.money-label {
    text-align: center;
    font-weight: bold;
    color: #aaa
}

.money-cost {
display:flex;
justify-content: space-between;
border-bottom: 1px #ccc solid;
padding-bottom: 5px;
padding-top:5px;
}

.withdraw-table {
    width: 100%;
    text-align: center;
}

.withdraw-table-heading {
    color: #aaa;
    font-weight: normal;
    border-bottom: 1px #ccc solid;
    padding-bottom: 5px;
    padding-top:5px;
}