.class-day {
  background: linear-gradient(
    240deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(212, 212, 212, 1) 100%
  );
  border-radius: 10px;
  margin: 5px;
  box-shadow: 5px 5px 10px #888888;
}

.edit-class-top {
  width: 50%;
  padding: 5px;
}
