.user-panel {
  width: 200px;
  height: 100px;
  background: rgb(4, 99, 175);
  background: linear-gradient(
    232deg,
    rgba(4, 99, 175, 1) 0%,
    rgba(1, 54, 94, 1) 100%
  );
  color: white;
  border-radius: 5px;
  padding: 5px;
}
