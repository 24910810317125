.small-text {
  font-size: 11px;
  color: #aaa;
}

.stage-two-box {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  margin-top: 5px;
}

.lead-notes {
  font-size: 13px;
}

.leads-open-note {
  cursor: pointer;
  font-size: 12px;
  font-weight: 100;
  margin-left: 10px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 8px;
}

.leads-open-note:hover {
  color: green;
}

.lead-notes-outline {
  background-color: #787878;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-grow:1;
}

.lead-notes-title {
  color: white;
  font-size: 12px;
  margin-left: 3px;
  padding-bottom: 3px;
}

.leads-notes-container {

  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px 5px, 0px, 0px;
  background-color: #eee;
  flex-grow:1;
}

.leads-basic-details {
  width: 300px;
}

.leads-basic-name {
  font-weight: 600;
}

.leads-basic-subinfo {
  font-size: 14px;
}

.leads-test-container {
  margin-left: 10px;
  width: 400px;
}

.leads-decision {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:20px;

}

.enter-note-div {
  background-color: white;
  border-top: #787878;
  width: 100%;
  max-height: 430px;
  overflow-y: hidden;
  height: 40px;
  min-height: 40px;
  padding: 10px;
  font-size: 14px;
}
.enter-note-div:focus {
  outline: none;
}

/* .lead-choose-test {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  cursor: pointer;
}

.lead-choose-test:hover {
  background-color: #eee;
} */

.choose-timetable-div {
  cursor: pointer;
}

.choose-timetable-div:hover {
  background-color: #eee;
}