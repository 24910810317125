.attendance-frame {
  width: 200px;
  height: 150px;
  border-radius: 0.5rem;
  margin: 5px;
  cursor: pointer;
  background: linear-gradient(49deg, #01425c 2.96%, #02697e 98.43%);
  box-shadow: 3px 3px 10px #636d71;
}

.attendance-frame:hover {
 filter: brightness(1.2)
}

.coming-frame {
  width: 200px;
  height: 150px;
  border-radius: 0.5rem;
  margin: 5px;
  cursor: pointer;
  background: linear-gradient(
    232deg,
    rgba(174, 175, 4, 1) 0%,
    rgba(94, 90, 1, 1) 100%
  );
  box-shadow: 3px 3px 10px #636d71;
}

.coming-frame:hover {
 filter: brightness(1.2)
}



.extra-frame {
  width: 200px;
  height: 108px;
  border-radius: 0.5rem;
  margin: 5px;
  cursor: pointer;
  background: linear-gradient(
    80deg,
    rgb(0, 65, 54) 0%,
    rgb(1, 124, 110) 100%
  );
  box-shadow: 3px 3px 10px #636d71;
}

.extra-frame:hover {
  filter: brightness(1.2)
}

.makeup-frame:hover {
 filter: brightness(1.2)
}

.attendance-students-frame {
  width: 185px;
  height: 52px;
  background: linear-gradient(
    77.08deg,
    rgba(160, 160, 160, 0) 22.24%,
    rgba(1, 128, 156, 0) 76.22%
  );
  border-radius: 6px;
  padding: 3px;
  position: relative;
  left: 8px;
  display: flex;
  font-size: 12px;
  font-weight: 500;
}
