.sidebar {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.sidebar-titles {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  height: 35px;
  padding: 15px;
  color: #ddd;
}

.center-name {
  padding-top: 20px;
  text-align: center;
  font-family: "itc_avant_garde_gothic_ltbook";
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 2px 2px 5px #0c0c0c;
}

/* .side-bar-container {
  width: 200px;
  background: linear-gradient(
    49deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 72, 105, 1) 0%,
    rgba(0, 157, 189, 1) 100%
  );
  color: white;
} */
