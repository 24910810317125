.good {
  color: rgb(21, 163, 2);
}

.worse {
  color: goldenrod;
}

.bad {
  color: red;
}

.owed-data-grid {
  height: 0.5rem;
}

.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}
