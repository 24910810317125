.tcell {
  text-align: center;
}

.table {
  width: 45%;
}

.student-link {
  text-decoration: none;
  color: black;
}

.student-link:hover {
  color: rgb(0, 132, 209);
}
