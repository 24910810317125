.MenuItem {
  color: black;
}

/* Increase the specificity */
.MenuItem.Mui-selected {
  color: rgb(0, 123, 194);
  background: black;
}

.note-text {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}

.note-title {
  font-size: 0.9rem;
}

.class-title {
  text-decoration: none !important;
  font-size: 24px;
  color: black;
}

.class-title:hover {
  color: rgb(0, 117, 138);
}

.attendance-title {
  user-select: none;
}

.attendance-month {
  user-select: none;
  width: 12rem;
  text-align: center;
}

.attendance-widgets-layout {
  display: flex;
  flex-wrap: wrap;
}

.attendance-class {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 0.5rem;
  padding: 0.5rem;
  align-items: center;
}

.attendance-widget {
  width: 8.7rem;
  border-radius: 2rem;
  padding: 0.5rem;
  font-size: 0.7rem;
  margin: 0.2rem;
  height: 10.5rem;
  /*   box-shadow: 2px 2px 5px #888888; */
}

.attendance-class-title {
  font-size: 1.2rem;
  width: 10rem;
}

.feedback-scores {
  margin: 0.3rem;
  padding: 0.4rem;
  border-radius: 0.8rem;
  text-align: center;
  color: rgb(255, 255, 255);
  width: 7rem;

  font-family: Inter;
  font-weight: 400;
  font-size: 0.6rem;
}

.attendance-date {
  color: #1e1e1e;
  font-family: Inter;
  text-align: center;
  font-weight: 600;
}

.student-recent {
  font-size: 14px;
}

.student-recent-title {
  color: rgb(0, 117, 138);
}
.student-chat-title {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #003274;
}

.student-chat-message {
  font-size: 11px;
}

.student-chat-date {
  font-size: 10px;
  color: gray;
  text-align: right;
  width: 100%;
}

.student-chat-box {
  width: 100%;
  padding: 5px;
  margin: 2px;
}
