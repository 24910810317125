.chatbox-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 400px;
  min-height: 100px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  background: #485563; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #29323c, #485563);
  background: linear-gradient(to top, #29323c, #485563);
}

.chat-input {
  /* width: 400px;
  position: relative;
  bottom: 0px;
  border-top: 1px solid #ccc;
  font-size: 14px;
  padding: 3px;
  height: 25px;
  background-color: white; */
}

.chat-input:focus {
  border: none;
  outline: none;
}

.chats {
  flex-grow: 1;
  overflow-x: hidden;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.chat-container {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
  margin: 2px;
  background-color: white;
  overflow-anchor: none;
}

.anchor {
  overflow-anchor: auto;
  height: 1px;
}

.chat-header {
  font-size: 11px;
}

.chat-content {
  font-size: 14px;
}

.chat-timestamp {
  font-size: 11px;
  color: "#aaa";
  text-align: right;
}

.chat-tags {
  background-color: white;
  position: relative;
  bottom: 10px;
  margin-left: 10px;
  border-radius: 5px;
  width: 300px;
}

.tags-staff {
  display: flex;
  align-items: "center";
  margin: 3px;
  padding: 5px;
  border-radius: 5px;
}

.tags-staff:hover {
  background-color: #eee;
}

.old-chat-description {
  background-color: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  margin: 2px;
}
