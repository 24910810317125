.absent-table-labels {
  font-size: 0.7rem;
}

.flatp {
  display: none;
}

.makeup-timetable {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.makeup-timetable-closed {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  color: #aaa;
}

.makeup-timetable:hover {
  background-color: #eee;
  cursor: pointer;
}

.makeup-info {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  font-size: 14px;
}

.muclass {
color: black;
padding: 5px;
border-radius: 5px;
cursor: pointer;
}
.muclass:hover {
color: black;
padding: 5px;
border-radius: 5px;
background-color: #eee;
}

.muclass-disabled {
  color: #aaa;
  padding: 5px;
border-radius: 5px;
}

.mu-class-details{
  background-color: white;
  cursor: pointer;
}

.mu-class-details:hover{
  background-color: #eee;
}