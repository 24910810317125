.pending-container {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  display: flex;
}

.pending-container-labels {
  display: flex;
  font-size: 14px;
}
