.main-table {
    with:400px;
    padding: 5px;
}

.profit {
    font-weight: bold;
}

.figure {
    text-align: right;
    width:200px;
   
}

.top-border {
  border-top: 1px solid #393939;

}

.difference {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}