.feedback-frame {
  display: flex;
  border-radius: 5px;
}

.feedback-frame-disabled {
  display: flex;
  color: #aaa;
}

.feedback-frame:hover {
  background-color: #eee;
}

.feedback-students-name {
  padding: 10px;
  width: 300px;
  min-width: 300px;
}

.feedback-absent-reason {
  padding: 10px;
}

.feedback-notes {
  font-size: 13px;
  text-align: left;
  flex-grow: 1;
  margin-left: 5px;
}
