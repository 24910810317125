.resource-container {
    width:400px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
}

.resource {
    color: white;
    border-radius: 5px;
    padding: 5px;
    font-size: 14px;
    height: 100px;
    font-weight: bold;
}

.cur-link {
  text-decoration: none;
  color: black;
}

.cur-link:hover {
  color: rgb(0, 88, 119);
}

.cur-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 2px 2px 5px #888;
}

.cur-table{
  width:100%;
  border-collapse: collapse; 
}

.cur-table td {
  border: 1px solid #ccc;
  margin: 0px;
  padding: 5px;
}

.cur-table th {
  border: 1px solid #ccc;
  margin: 0px;
  padding: 5px;
}

.cur-breadcrumbs {
  color: grey;
  text-decoration: none;
  margin-bottom: 10px;
}

.cur-breadcrumbs:hover {
  color: rgb(0, 77, 125);
 
}