.notification {
  width: 250px;
  padding: 0.5rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}

.cancel-icon {
  font-size: 0.5rem;
}

.notification-link {
  text-decoration: none;
  color: rgb(0, 105, 167);
}

.notification-title {
  font-weight: 600;
}

.notification-message {
  font-size: 0.8rem;
}

.notifications-hero {
  margin-left: 5px;

}

.notification-timestamp {
  color: gray;
  font-size: 0.7rem;
}
