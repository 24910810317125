.shift-cell {
    border: 1px solid #ccc;
    width: 200px;
    padding: 3px;
    text-align: center;
}

.shift-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
}


.teachers-name {
    color: white;
    background-color: #00576d;
    padding: 3px;
    font-size: 24pt;
    text-align: center;
  
}

.name-of-class {
    font-weight: bold;
    font-size: 12pt
}

.class-details {
 font-size: 11pt
}